@font-face {
	font-family: 'Nexa';
	src: url('#{$assets-url}/fonts/Nexa-Bold.woff2') format('woff2'),
		url('#{$assets-url}/fonts/Nexa-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Nexa';
	src: url('#{$assets-url}/fonts/Nexa-Light.woff2') format('woff2'),
		url('#{$assets-url}/fonts/Nexa-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Nexa';
	src: url('#{$assets-url}/fonts/Nexa-Black.woff2') format('woff2'),
		url('#{$assets-url}/fonts/Nexa-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Nexa';
	src: url('#{$assets-url}/fonts/Nexa-Regular.woff2') format('woff2'),
		url('#{$assets-url}/fonts/Nexa-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}








// ====================
//  GENERAL
// ====================


a {
    color: $brand-secondary;
    text-decoration: underline;

    &:hover, &:focus {
    	color: lighten($brand-secondary, 15%);
    }
}

body {
	padding-top: 0;
	overflow-x: hidden;
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
	--bs-gutter-x: 3rem;
}

.text-small {
	font-size: 0.75rem;
	padding-right: 2rem;
	display: block;
	line-height: 1.5;
}

.oc-progress-bar {
  height: 1rem;
  background: #F3E500;
}


.bg-secondary {
	background-color: $brand-secondary !important;
}

.oc-progress-bar {
	height: 1rem;
	background: $brand-primary;
}

.btn-aie.btn-primary {
	padding: 1.75rem 2.5rem 1.5rem;

	background-color: $brand-primary;
	border: 0;
	border-radius: 0;
	
	font-size: 1.125rem;
	text-transform: uppercase;
	color: $brand-secondary;

	transition: all ease .3s;

	&:hover, &:focus {
		background-color: lighten($brand-primary, 5%);
		text-decoration: none;
	}
}

.btn-aie.btn-primary.btn-aie--vertical {
    position: fixed;
    top: 10rem;
    right: 0;
    transform: rotate(-180deg);

    padding: 3.5rem 1.5rem 3.5rem 1.25rem;

    font-size: 1.125rem;
    writing-mode: vertical-rl;
    color: $brand-secondary;

    transition: all ease-in-out .3s;

    z-index: 999;

    &:hover {
    	padding-left: 1.75rem;
    }

    &.active {
    	z-index: 1050;
    	right: 35rem;
    }

    @media screen and (max-width: $xs-max) {
    	display: none;
    }
}

#offcanvas-ponuka-btn {
	right: -5rem;
	
	&.visible {
		right: 0;
	}

	&.visible.active {
		right: 35rem;
	}
}

.btn-aie.btn-secondary {
	padding: 1.75rem 14rem 1.5rem 6rem;

	background-color: $brand-secondary;
	border: 0;
	border-radius: 0;
	
	font-size: 1.3125rem;
	text-transform: uppercase;

	transition: all ease .3s;

	span {
		position: relative;

		&:after {
			@include after-before;

			left: 130%;
			top: 50%;
			transform: translateY(-70%);
			
			width: 6.25rem;
			height: 0.75rem;

			background: url('#{$assets-url}/images/arrow_100.svg') no-repeat center;

			transition: all ease .3s;
		}
	}

	&:hover, &:focus {
		background-color: lighten($brand-secondary, 2%);

		span:after {
			left: 145%;
		}
	}

	@media screen and (max-width: $xs-max) {
		padding: 1.25rem 7.5rem 1rem 3rem;

		span:after {
			width: 2.8125rem;
			background: url('#{$assets-url}/images/arrow_45_color.svg') no-repeat center;
		}
	}
}

.btn-aie.btn-secondary.btn-secondary--sm {
	padding: 1.25rem 8rem 1rem 2rem;

	font-size: 1.125rem;

	span {
		position: relative;

		&:after {
			@include after-before;

			left: 130%;
			top: 50%;
			transform: translateY(-70%);
			
			width: 4rem;
			height: 0.75rem;

			background: url('#{$assets-url}/images/arrow_100.svg') no-repeat right;

			transition: all ease .3s;
		}
	}

	&:hover, &:focus {
		background-color: lighten($brand-secondary, 2%);

		span:after {
			left: 145%;
		}
	}

	@media screen and (max-width: $xs-max) {
		padding: 1.25rem 7.5rem 1rem 3rem;

		span:after {
			width: 2.8125rem;
			background: url('#{$assets-url}/images/arrow_45_color.svg') no-repeat center;
		}
	}
}

.btn-aie.btn-outline-secondary {
	padding: 1.5rem 7rem 1.25rem 3rem;

	border: 1px solid rgba($brand-secondary, .25);
	border-radius: 0;
	color: $brand-secondary;

	font-size: 1rem;
	text-transform: uppercase;

	span {
		position: relative;

		&:after {
			@include after-before;

			left: 115%;
			top: 50%;
			transform: translateY(-70%);
			
			width: 2.8125rem;
			height: 0.75rem;

			background: url('#{$assets-url}/images/arrow_45_color_dark.svg') no-repeat center;

			transition: all ease .3s;
		}
	}

	&:hover, &:focus {
		background-color: transparent;
		border: 1px solid rgba($brand-secondary, .5);
		text-decoration: none;

		span:after {
			left: 120%;
		}
	}

	@media screen and (max-width: $xxs-max) {
		padding: 1.25rem 6rem 1rem 2rem;
	}
}

.btn-aie.btn-outline-primary {
	padding: 1rem 7rem .75rem 3rem;

	border: 1px solid rgba($brand-primary, .25);
	border-radius: 0;
	color: white;

	font-size: .875rem;
	letter-spacing: .75px;
	text-transform: uppercase;

	span {
		position: relative;

		&:after {
			@include after-before;

			left: 115%;
			top: 50%;
			transform: translateY(-70%);
			
			width: 2.8125rem;
			height: 0.75rem;

			background: url('#{$assets-url}/images/arrow_45_color.svg') no-repeat center;

			transition: all ease .3s;
		}
	}

	&:hover, &:focus {
		background-color: transparent;
		border: 1px solid rgba($brand-primary, .5);
		text-decoration: none;

		span:after {
			left: 120%;
		}
	}
}

.text-primary {
	color: $brand-primary !important;
}


.aie-modal {
	.modal-title {
		font-size: 1.25rem;
		color: $brand-secondary;
	}

	.modal-content {
		padding: 1.5rem;

		border-radius: 0;
		border: 0;
	}

	.alert {
	    margin-top: 0.5rem;
	    padding: 2.5rem 2rem;
	    
	    border: 0;
		border-radius: 0;
	    background-color: $brand-primary;
	    color: $brand-secondary;
	    
	    font-weight: normal;
	}
}

.aie-popup {

	.modal-content {
		background-color: transparent;
		border-radius: 0;
	}

	.modal-body {
		padding: 0;
	}

	&--content {
		display: grid;
		grid-template-rows: repeat(10, minmax(1rem, 1fr));
		grid-template-columns: repeat(12, minmax(1rem, 1fr));

		@media screen and (max-width: 575px) {
			display: flex;
			flex-direction: column;
		}
	}

	&--image {
		grid-row: 1 / span 10;
		grid-column: 1 / span 7;

		@media screen and (max-width: 1199px) {
			grid-column: 1 / span 9;			
		}
		@media screen and (max-width: 991px) {
			grid-row: 1 / span 6;
			grid-column: 1 / span 12;
		}

		overflow: hidden;

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	&--form {
		position: relative;

		grid-row: 2 / span 8;
		grid-column: 7 / span 6;

		padding: 5rem;

		@media screen and (max-width: 1199px) {
			grid-column: 5 / span 8;			
			padding: 5rem 4rem;
		}

		@media screen and (max-width: 991px) {
			grid-row: 3 / span 8;
			grid-column: 2 / span 10;			

			padding: 3.75rem 2.5rem;
		}

		background: #fff;
		text-align: center;

		form {
			text-align: left;
		}

		.btn-close {
			position: absolute;
			top: 1rem;
			right: 1rem;
			z-index: 10;
		}

		h3 {
			margin: 0 2rem 2rem;
			font-size: 1.625rem;
			line-height: 1.3;
    		text-transform: uppercase;

    		@media screen and (max-width: 1199px) {
				margin: 0 1rem 2rem;
			}
			@media screen and (max-width: 991px) {
				margin: 0 0 2rem;
				font-size: 1.425rem;
			}
		}
		p {
			margin: 0 2rem 2rem;

			@media screen and (max-width: 1199px) {
				margin: 0 1rem 2rem;
			}
			@media screen and (max-width: 991px) {
				margin: 0 0 2rem;
			}
		}

		.form-control {
			background-color: transparent;
			border: 0;
			border-radius: 0;
			border-bottom: 1px solid $brand-secondary;

			color: $brand-secondary;
		}

		.form-floating > label {
			padding-left: 0;
			padding-right: 0;

			color: $brand-secondary;
		}

		.form-floating > .form-control, .form-floating > .form-control-plaintext {
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 0;
		}

		.form-floating > .form-control:focus ~ label, 
		.form-floating > .form-control:not(:placeholder-shown) ~ label, 
		.form-floating > .form-control-plaintext ~ label, 
		.form-floating > .form-select ~ label {
			transform: scale(0.85) translateY(-0.5rem) translateX(-0.05rem);
		}
	}

	&--btn-row {
		display: flex;
		justify-content: center;
	}
}

.modal-backdrop {
	--bs-backdrop-opacity: 0.75;
}


// ====================
//  NAVBAR
// ====================


.navbar {
	height: 165px;

	font-size: 1.125rem;
	font-weight: 300;
}

.navbar-brand {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.dropdown-menu {
	min-width: unset;
	padding-top: 0;
	padding-bottom: 0;
}

.dropdown-menu[data-bs-popper] {
	left: unset;
	right: -0.5rem;
	// transform: translateX(-50%);
    border-radius: 0;
    border: 0;
    background-color: $brand-secondary;
}

.dropdown-item {
	padding: .75rem 1.5rem;
	color: white;

	font-size: 1.125rem;
	font-weight: 300;

	&:hover, &:focus {
		background-color: $brand-primary;
	}
}


// =======================
// MENU BURGER ANIMATION
// =======================

.hidden {
	visibility:hidden;position: absolute;   width: 0; height: 0;
}




.menu-burger {
	display: block;
	position: fixed;
	left: 2.5rem;

	width: 5rem;
	height: 5rem;
	border-radius: 0;
	box-shadow: 0 0 grid(1) rgba(0, 0, 0, 0.25), 0 0 grid(0.2) rgba(0, 0, 0, 0.1);
	background-color: transparent;
	margin: 0.5rem;

	transition: background-color ease-in-out .3s;

	z-index: 100;

	&--arrow-back {
		display: flex;
		justify-content: center;
		align-items: center;
		
		img {
			transition: margin-left ease-in-out .2s;
		}

		&:hover, &:focus {
			img { 
				margin-left: -1rem; 
			}
		}
	}

	&.active {
		z-index: 1050;
	}

	&.with-bg {
		background-color: rgba($brand-secondary, 0.5);
	}


	span {
		@include menu(1.75rem, 0.0775rem, 0.5rem, white);
	}

	input:checked + span {
		z-index: 1050;
		@include menu-close();
	}

	&:hover {
		cursor: pointer;
	}

	@media screen and (max-width: $xxs-max) {
		left: 1rem;
	}

}


.lang-picker {
	position: absolute;
	right: 3.5rem;

	@media screen and (max-width: $xxs-max) {
		right: 2rem;
	}
}


// =========================
//  Offcanvas navigation
// =========================

.offcanvas-navigation.offcanvas.offcanvas-start {

	width: 28rem;
	border-right: 0;
	opacity: .9;	

	.offcanvas-body {
		background: $brand-primary;
	}

} 


.offcanvas-navigation-list {
	display: flex;
	flex-direction: column;

	padding-top: 8rem;
	padding-left: 3.75rem;
	padding-right: 6.25rem;

	a {
		margin-bottom: 1.25rem;

		color: $brand-secondary;
		text-transform: uppercase;
		text-decoration: none;

		transition: margin ease-in-out .2s;

		&:hover, &:focus {
			margin-left: 1rem;
		}
	}

	@media screen and (max-width: $xxs-max) {
		padding-left: 2.25rem;
	}
}



// ==========================
//  Offcanvas cenová ponuka
// ==========================

.offcanvas--cenova-ponuka.offcanvas.offcanvas-end {
	border-left: 0;
	width: 35rem;
}

.offcanvas--cenova-ponuka {

	overflow-y: scroll;

	-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	
	&::-webkit-scrollbar {
		display: none;
	}

	.offcanvas-body {
		position: relative;
		padding: 4rem 2.5rem 2.5rem;

		.btn-close {
		    position: absolute;
		    top: 3.9rem;
		    right: 2.5rem;
		    border: 1px solid $brand-secondary;
		    padding: 1rem;
		    border-radius: 0;
		}
	}


	&--form-button {
		width: 100%;
	    margin-top: 3.5rem;
	}

	form {
		h4 {
			line-height: 1.4;
		}

		.form-control {
			background-color: transparent;
			border: 0;
			border-radius: 0;
			border-bottom: 1px solid $brand-secondary;

			color: $brand-secondary;
		}

		.form-control:focus {
			box-shadow: unset;
			border-bottom: 2px solid $brand-secondary;
		}

		.form-floating--group {
			display: flex;
			align-items: center;
			
			position: absolute;
			right: 0;
			top: 0;

			height: 100%;
			padding-left: 1rem;
			padding-right: 1rem;

			background-color: rgba($brand-secondary, 0.05);
			border: 0;
			border-radius: 0;
			color: $brand-secondary;
		}

		.form-floating > label {
			padding-left: 0;
			padding-right: 0;

			color: $brand-secondary;
		}

		.form-floating > .form-control, .form-floating > .form-control-plaintext {
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 0;
		}

		.form-floating > .form-control:focus ~ label, 
		.form-floating > .form-control:not(:placeholder-shown) ~ label, 
		.form-floating > .form-control-plaintext ~ label, 
		.form-floating > .form-select ~ label {
			transform: scale(0.85) translateY(-0.5rem) translateX(-0.05rem);
		}

		.form-select {
			background-color: transparent;
			border: 0;
			border-radius: 0;
			border-bottom: 1px solid $brand-secondary;

			color: $brand-secondary;

			padding-left: 0;
			padding-right: 0;
			padding-bottom: 0;
		}

		.form-select:focus {
			box-shadow: unset;
			border-bottom: 2px solid $brand-secondary;
		}


		.form-check {
			padding-left: 3.5rem;

			color: $brand-secondary;
		}

		.form-check .form-check-input {
			width: 2.5rem;
    		height: 2.5rem;

			margin-left: -3.5rem;

    		background-color: transparent;
		}

		.form-check-input[type=checkbox] {
			border-radius: 0;
			border-color: $brand-secondary;
		}

		.form-check-input:checked[type=checkbox] {
			// background-color: $brand-secondary;
			background-image: url('#{$assets-url}/images/checkbox_checked.svg');
		}

		.form-check-input:focus {
			box-shadow: 0 0 0 0.25rem rgba($brand-secondary, .25);
		}

		.form-check .form-check-label {
			padding-top: 0.75rem;
		}
	}
}




// =======================
//  HERO
// =======================

.aie-hero {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	// padding-top: 8rem;
	padding-bottom: 15rem;
}

.aie-hero--rodinne-domy {
	padding-bottom: 10rem;
}


.aie-headline {
	position: relative;
	padding-top: 3rem;

	font-size: 3.25rem;
	font-weight: 700;
	line-height: 1.5;
	text-transform: uppercase;

	&--invert {
		color: #FFF;
	}

	&::before {
		@include after-before;
		top: 0;
		left: 0;

		width: 45px;
		height: 12px;

		background: $brand-primary;
	}

	@media screen and (max-width: $md-max) {
		font-size: 2.75rem;
	}

	@media screen and (max-width: $xxs-max) {
		font-size: 2.25rem;
		overflow-x: auto;
	}

	@media screen and (max-width: 450px) {
		font-size: 1.75rem;
		overflow-x: auto;
	}

	&--h1 {
		font-size: 3.75rem;
		line-height: 1.3;

		@media screen and (max-width: 450px) {
			font-size: 2rem;
			overflow-x: auto;
		}
	}

	&--big {
		font-size: 3.25rem;
	}
}

.aie-subheadline {
	position: relative;

	margin-bottom: 2.5rem;
	padding-left: 5rem;
	padding-top: 0.5rem;

	font-size: 1.625rem;
	text-transform: uppercase;

	&:before {
		@include after-before;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: 2.5rem;
		height: 0.5rem;
		background-color: $brand-primary;

	}
}



// =========================
//  SECTION - PONUKA
// =========================

.ponuka {
	position: relative;

	margin-top: -12.5rem;
	margin-bottom: 12.5rem;

	@media screen and (max-width: $md-max) {
		margin-top: -6.25rem;
		margin-bottom: 6.25rem;
	}
}

.ponuka-bg-pattern {

	position: absolute;
	top: -2.5rem;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -10;

	width: 100%;
	height: 100%;

	background: url('#{$assets-url}/images/light_illustration.svg') space center top;
}

.ponuka-card {
	position: relative;
	transition: all ease .3s;
	box-shadow: 0 0.5rem 0.5rem 0.5rem rgba(0,0,0,.05);

	&:hover, &:focus {
		transform: scale(1.1);
		box-shadow: 0 0 1.5rem 1.5rem rgba(0,0,0,.1);
		z-index: 1000;
	}

	@media screen and (max-width: $md-max) {
		margin-bottom: 2.5rem;
	}

	&--headline {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		margin: 0;
		padding: 2.5rem;
		height: 12.5rem;

		background: white;

		text-align: center;
		text-transform: uppercase;

		.small {
			font-size: 1.25rem;
			font-weight: 300;

			margin-bottom: 1.25rem;
		}

		.big {
			font-size: 2.25rem;
			font-weight: 900;
			letter-spacing: .5px;
		}
	}

	&--image {
		height: 35rem;
		padding: 1.5rem;
		background-size: cover !important;

		@media screen and (max-width: $md-max) {
			height: 25rem;
		}
	}

	&--button {
		position: absolute;
		bottom: 0;

		width: 100%;
		height: 100%;
		padding-top: 9rem;

		background: transparent linear-gradient(180deg, #0C6A5B00 60%, #0C6A5BE3 90%, #0C6A5B 100%) 0% 0% no-repeat padding-box;

		font-size: 1.125rem;
		text-transform: uppercase;
		text-align: center;
		letter-spacing: 1px;
		color: white;

		span {
			position: absolute;
			bottom: 3rem;
			left: calc(50% - 3.75rem);
			transform: translateX(-25%);
			
			&:after {
				@include after-before;
				
				width: 3.75rem;
				height: 0.75rem;

				background: url('#{$assets-url}/images/arrow_45_color.svg') no-repeat center;

				top: 50%;
				left: 110%;
				transform: translateY(-80%);

			}
		}
	}

	&--badges {
		// display: flex;
		// flex-direction: column;
	}
	&--badge {
		display: inline-block;

		margin-bottom: 0.75rem;
		padding: 0.5rem 1rem 0.25rem;
		
		background: white;
		border-left: 0.375rem solid $brand-primary;
		text-transform: uppercase;

		strong {
			font-weight: 800;
		}

		&--xl {
			display: block;

			background: $brand-secondary;
			border-left: 0.5rem solid $brand-primary;

			font-size: 1rem;
			line-height: 2rem;
			text-transform: none;
			color: white;

			strong {
				display: block;

				font-size: 1.75rem;
				font-weight: 800;
				text-transform: uppercase;
			}
		}
	}

	&:hover &--button, &:focus &--button {
		background: transparent linear-gradient(180deg, #F3E53200 60%, #F3E532E2 90%, #F3E532 100%) 0% 0% no-repeat padding-box;
		
		text-decoration: none;
		color: $brand-secondary;

		span:after {
			background: url('#{$assets-url}/images/arrow_45_green.svg') no-repeat center;				
		}
	}
}

// =====================================
//  SECTION - PREČO FOTOVOLTAIKA?
// =====================================

.preco-fotovoltaika {
	margin-bottom: 12.5rem;

	p {
		font-weight: 300;
	}
}

.preco-ftv-accordion {
	// margin-top: 5rem;

	--bs-accordion-active-bg: #{rgba($brand-secondary,.1)};
	--bs-accordion-btn-focus-border-color: #{rgba($brand-secondary,.2)};
	--bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem #{rgba($brand-secondary,.2)};

	--bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23006A5B'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");

	.accordion-header {
		margin-top: 0;
	}

	.accordion-button {
		padding: 2.25rem;
		font-size: 1.25rem;
		font-weight: 700;
		color: $brand-secondary;
	}
	.accordion-body {
		padding: 2rem 2.25rem;
		font-weight: 300;
		color: $brand-secondary;
	}
}



// ==================================================
//  SECTION - DOTÁCIA AŽ 3 500 €
// ==================================================

.dotacia-4025 {
	margin-bottom: 10rem;

	&--wrapper {
		position: relative;
		background: #13181e;
	}

	&--content {
		padding: 4rem 4rem 0;
		margin-bottom: -15%;
		
		position: relative;
		z-index: 100;


		@media screen and (max-width: 1199px) {
			padding: 3.5rem 3.5rem 0;
		}

		@media screen and (max-width: 991px) {
			padding: 3rem 3rem 0;
		}

		@media screen and (max-width: 549px) {
			padding: 1.5rem 1.5rem 0;
		}
	}

	&--image {
		position: relative;
		z-index: 50;
	}

	p {
		font-weight: 300;
	}
}





// ==================================================
//  SECTION - PREDAJ FTV ELEMENTOV
// ==================================================

.predaj-fv-komponentov {
	position: relative;

	&--headline {
		position: sticky;
		top: 2.5rem;
		align-self: flex-start;

		@media screen and (max-width: 1199px) {
			position: static;
			top: 0;
		}
	}

	&--accordion {

		margin-top: 1.25rem;

		--bs-accordion-bg: #{rgba($brand-secondary,.05)};
		--bs-accordion-active-bg: #{rgba($brand-secondary,.1)};
		--bs-accordion-btn-focus-border-color: #{rgba($brand-secondary,.2)};
		--bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem #{rgba($brand-secondary,.2)};

		--bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23006A5B'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");

		.accordion-header {
			margin-top: 0;
		}

		.accordion-button {
			padding: 1.25rem;
			font-size: 1rem;
			font-weight: 700;
			color: $brand-secondary;
		}
		.accordion-body {
			padding: 2rem 2.25rem;
			font-weight: 300;
			color: $brand-secondary;
		}
	}
}

.aie-produkt {
	background: rgba($brand-secondary,.05);
    padding: 2.5rem;

	&--col {
		// padding-top: 5rem;
	}
	&--headline {
		display: flex;

		margin-top: -2.5rem;
		
		margin-bottom: 2.5rem;

		@media screen and (max-width: 575px) {
			flex-direction: column-reverse;
			gap: 1rem;
		}
		
		h3 {
			flex: 1 1 auto;

			font-size: 1.625rem;
			text-transform: uppercase;

			position: relative;
			margin-top: 2.5rem;
			padding-top: 2.5rem;

			&::before {
				@include after-before;
				top: 0;
				left: 0;

				width: 45px;
				height: 8px;

				background: $brand-primary;
			}

			@media screen and (max-width: 767px) {
				font-size: 1.5rem;
			}
		}
	}
	&--image {
		position: relative;
    	overflow: hidden;

		width: 10rem;

		display: block;
		margin-top: 1rem;
		margin-right: -2.5rem;
		margin-bottom: -1rem;
		flex: 0 0 auto;

		@media screen and (max-width: 575px) {
			width: auto;
			transform: unset;
			// margin: 0;
			margin-top: 2rem;
			margin-left: -2.5rem;
			margin-bottom: -2rem;
			text-align: center;
		}

		img {
			object-fit: contain;

			position: absolute;
		    right: -30%;
		    top: 50%;
		    transform: translateY(-50%);
			
			@media screen and (max-width: 575px) {
				position: static;
				transform: unset;

				max-width: 16rem;
			}
		}

	}
}

.aie-pricesheet {
	margin-top: 1.25rem;

	&--price {
		font-size: 1.25rem;
		font-weight: 700;
	}
}




// ==================================================
//  SECTION - PROJEKCIA A REALIZÁCIA
// ==================================================


.projekcia-realizacia {
	padding-bottom: 12.5rem;

	&--content {
		padding-top: 5rem;
		
		font-size: 1.125rem;
		line-height: 2.5;
		font-weight: 300;

		color: $brand-secondary;
	}

	@media screen and (max-width: $md-max) {
		padding-bottom: 10rem;

		&--content {
			line-height: 2;
		}
	}

	@media screen and (max-width: $sm-max) {
		padding-bottom: 7.5rem;

		&--content {
			padding-top: 2rem;
			line-height: 1.5;
		}
	}
}


// ==================================================
//  SECTION - CENOVÁ PONUKA
// ==================================================

.cenova-ponuka {
	padding-bottom: 12.5rem;

	&--headline {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		padding-top: 10rem;
		padding-bottom: 15rem;
	}

	&--form {
		position: relative;
		margin-top: -10rem;

		&-headline {
			display: inline-block;
			margin: 0;
			padding: 1.875rem 6rem 1.5rem;
			
			font-size: 1.625rem;
			text-transform: uppercase;
			letter-spacing: .5px;
			
			background: $brand-secondary;
			color: white;

			@media screen and (max-width: $lg-max) {
				padding-left: 4rem;
				padding-right: 4rem;
			}

			@media screen and (max-width: $xxs-max) {
				padding-left: 2.5rem;
				padding-right: 2.5rem;
			}

		}

		&-button {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%) translateY(50%);

			@media screen and (max-width: $xs-max) {
				left: 0;
				width: 100%;
				transform: translateY(100%);
			}
		}



		form {
			position: relative;
			padding: 5rem 6rem;

			background: $brand-primary;

			@media screen and (max-width: $lg-max) {
				padding: 4.5rem 4rem;
			}

			@media screen and (max-width: $xxs-max) {
				padding: 2.5rem;
			}
		}

		.form-control {
			background-color: transparent;
			border: 0;
			border-radius: 0;
			border-bottom: 1px solid $brand-secondary;

			color: $brand-secondary;
		}

		.form-control:focus {
			box-shadow: unset;
			border-bottom: 2px solid $brand-secondary;
		}

		.form-floating--group {
			display: flex;
			align-items: center;
			
			position: absolute;
			right: 0;
			top: 0;

			height: 100%;
			padding-left: 1rem;
			padding-right: 1rem;

			background-color: rgba($brand-secondary, 0.05);
			border: 0;
			border-radius: 0;
		}

		.form-floating > label {
			padding-left: 0;
			padding-right: 0;
		}

		.form-floating > .form-control, .form-floating > .form-control-plaintext {
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 0;
		}

		.form-floating > .form-control:focus ~ label, 
		.form-floating > .form-control:not(:placeholder-shown) ~ label, 
		.form-floating > .form-control-plaintext ~ label, 
		.form-floating > .form-select ~ label {
			transform: scale(0.85) translateY(-0.5rem) translateX(-0.05rem);
		}

		.form-select {
			background-color: transparent;
			border: 0;
			border-radius: 0;
			border-bottom: 1px solid $brand-secondary;

			color: $brand-secondary;

			padding-left: 0;
			padding-right: 0;
			padding-bottom: 0;
		}

		.form-select:focus {
			box-shadow: unset;
			border-bottom: 2px solid $brand-secondary;
		}


		.form-check {
			padding-left: 3.5rem;

		}

		.form-check .form-check-input {
			width: 2.5rem;
    		height: 2.5rem;

			margin-left: -3.5rem;

    		background-color: transparent;
		}

		.form-check-input[type=checkbox] {
			border-radius: 0;
			border-color: $brand-secondary;
		}

		.form-check-input:checked[type=checkbox] {
			// background-color: $brand-secondary;
			background-image: url('#{$assets-url}/images/checkbox_checked.svg');
		}

		.form-check-input:focus {
			box-shadow: 0 0 0 0.25rem rgba($brand-secondary, .25);
		}

		.form-check .form-check-label {
			padding-top: 0.75rem;
		}
	}
}





// ==================================================
//  SECTION - AKO TO FUNGUJE
// ==================================================

.ako-to-funguje {
	padding-bottom: 12.5rem;
}

.ako-to-funguje--postup {
	display: flex;
	justify-content: space-between;

	width: 100%;
	margin-top: 2.5rem;

	list-style-type: none;
	padding-left: 0;

	@media screen and (max-width: $sm-max) {
		// flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
		.item {
			margin-bottom: 2.5rem;

		}
	}
	@media screen and (max-width: 382px) {
		.item {
			&:nth-child(2n):after { 
				display: none;
			}
		}
	}

	@media screen and (max-width: 271px) {
		.item {
			&:nth-child(n):after { 
				display: none;
			}
		}
	}

	.item {
		position: relative;

		width: 9%;
		min-width: 7rem;

		display: flex;
		flex-direction: column;
		align-items: center;

		.icon {

			display: flex;
			align-items: center;
			height: 6rem;
			margin-bottom: 1rem;

			transition: transform ease .3s;
		}

		.text {
			font-size: 0.8125rem;
			letter-spacing: .4px;
			text-transform: uppercase;
			text-align: center;
			
			transition: transform ease .3s;
		}

		&:after {
			@include after-before;

			left: 105%; 
			top: 50%;
			transform: translateY(-50%);

			width: 2.5rem;
			height: 0.6425rem;

			background: url('#{$assets-url}/images/ako_to_funguje/arrow_40_gray.svg') no-repeat center;

			@media screen and (max-width: $lg-max) {
				left: 95%;
			}

			@media screen and (max-width: $md-max) {
				left: 90%;
				width: 1.5rem;
				background-position: center right;
			}

			@media screen and (max-width: $sm-max) {

			}
		}

		&:last-child:after { 
			display: none;
		}

		&:hover, &:focus {
			.icon {
				transform: scale(1.2);
			}
			.text {
				transform: translateY(0.25rem);
			}
		}
	}
}



// ============================
//  SECTION - O NÁS
// ============================

.o-nas {
	margin-top: 17.5rem;
	padding-bottom: 12.5rem;

	&--headline {
		
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: cover;

		padding-top: 15rem;
		padding-bottom: 15rem;
	}


	&--content {
		font-size: 1.125rem;
		font-weight: 300;

		strong {
			font-weight: 500;
		}
	}

	&--nas-tim {
		padding-top: 10rem;
	}
}

.important-numbers {		
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	margin-top: -5rem;
	margin-bottom: 7.5rem;
	padding: 4rem 7.5rem;
	background-color: $brand-primary;	

	list-style-type: none;

	li {
		text-align: center;
		max-width: 20%;

		.number {
			display: block;

			font-size: 3rem;
			font-weight: 900;
			line-height: 1;

			small {
				font-size: 1.6875rem;
			}
		}

		.text {
			display: block;
			margin-top: .5rem;

			font-size: .875rem;
			text-transform: uppercase;
			line-height: 1.4;
		}
	}

	@media screen and (max-width: $lg-max) {
		padding-left: 5rem;
		padding-right: 5rem;
	}

	@media screen and (max-width: $md-max) {
		margin-bottom: 5rem;
	}

	@media screen and (max-width: $sm-max) {
		padding: 3.5rem 2rem;

		li {
			margin-left: 0.5rem;
			margin-right: 0.5rem;

			.number {
				font-size: 2.5rem;
				small { font-size: 1.5rem; }
			}
		}
	}

	@media screen and (max-width: $xs-max) {
		flex-direction: column;
		padding: 3.5rem 2rem;

		li {
			margin-top: 1.5rem;
			margin-bottom: 1.5rem;

			max-width: unset;
			
			.number {
				font-size: 3rem;
				small { font-size: 1.625rem; }
			}
		}
	}
}

.team-member {
	&--image {
		border-bottom: 0.5rem solid $brand-primary;

		img {
			width: 100%;
		}
	}

	&--icon {
		max-width: 4rem;
	}

	&--name {
		margin-top: 1.5rem;

		font-size: 1.125rem;
		text-transform: uppercase;
	}

	&--ocupation {
		margin-top: 1rem;

		font-size: 1rem;
		font-weight: 300;
	}

	&--contact {
		color: $brand-secondary;
		font-size: 0.875rem;
		word-break: break-all;

		&:hover, &:focus {
			color: lighten($brand-secondary, 15%);
		}
	}
}


// ==========================
//  SECTION - REALIZÁCIE
// ==========================

.realizacie {
	padding-bottom: 2.5rem;

	&--gallery {
		display: flex;
		flex-wrap: wrap;
		padding: 3.5rem 0 0;

		&-link {
			width: calc(100% / 5);
			height: 50rem;

			display: flex;
		    justify-content: center;
		    align-items: center;
		    overflow: hidden;

		    transition: all ease .3s;

		    img {
			    min-width: 100%;
			    min-height: 100%;
			    object-fit: cover;
		    }

		    &:hover {
		    	height: 55rem;

		    	margin-top: -2.5rem;
		    	margin-bottom: -5rem;
		    }

		}
	}

    @media screen and (max-width: $lg-max) {
    	padding-bottom: 2.5rem;

    	&--gallery {
    		padding: 5rem 0;

    		&-link {
		    	height: 40rem;
				&:hover { height: 45rem; }
    		}
    	}
    }

    @media screen and (max-width: $md-max) {
    	&--gallery-link {
	    	height: 30rem;
			&:hover { height: 35rem; }
		}
    }


    @media screen and (max-width: $sm-max) {
    	&--gallery-link {
	    	height: 25rem;
			&:hover { height: 30rem; }
		}
    }

    @media screen and (max-width: $xs-max) {
    	&--gallery-link {
	    	height: 20rem;
			&:hover { height: 25rem; }
		}
    }

    @media screen and (max-width: $xxs-max) {
    	&--gallery {
			flex-direction: column;
			padding: 2.5rem 0 0;

			&-link {
				width: 100%;
				height: 12.5rem;

			    &:hover {
			    	height: 15rem;

			    	margin-top: -1.25rem;
			    	margin-bottom: -2.5rem;

			    	z-index: 9999;
			    }

			}
		}
    }
}






// ==========================
//  SECTION - PARTNERI
// ==========================

.partneri {
	padding-bottom: 12.5rem;
	overflow: hidden;

	&--logos {
		position: relative;
		padding-left: 2rem;
		padding-right: 2rem;

		&-item {
			display: flex !important;
			justify-content: center;
			align-items: center;
			
			max-width: 20rem;
			margin: 0 auto;
			height: 8rem;

			padding-left: 2rem;
			padding-right: 2rem;
		}

		&-nav {
			opacity: .75;

			&-prev {
				cursor: pointer;

				padding: 2rem;

				position: absolute;
				left: -2rem;
				top: 50%;
				transform: translateY(-50%);

				transition: left ease .3s;

				&:hover, &:focus {
					left: -2.5rem;
				}
			}

			&-next {
				cursor: pointer;

				padding: 2rem;

				position: absolute;
				right: -2rem;
				top: 50%;
				transform: translateY(-50%);

				transition: right ease .3s;

				&:hover, &:focus {
					right: -2.5rem;
				}
			}
		}
	}
}


// ==========================
//  SECTION - CERTIFIKÁTY
// ==========================

.certifikaty {
	padding-bottom: 15rem;

	&--gallery {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 2.5rem 0 0;

		&-link {
			width: 19%;
			padding: 0.5rem;
			border: 1px solid rgba($brand-secondary, .25);

		    &:hover {
		    	border: 1px solid rgba($brand-secondary, .5);
		    }

		    @media screen and (max-width: $sm-max) {
		    	width: 48%;
		    	margin-bottom: 4%;
		    }

		    @media screen and (max-width: $xxs-max) {
		    	width: 100%;
		    	margin-bottom: 4%;
		    }

		}
	}
}



// ==========================
//  SECTION - PARTNERI
// ==========================


.aie-big-footer {
	padding: 3.75rem 0;
	
	font-size: 0.9375rem;
	font-weight: 300;
	line-height: 1.4;

	background: $brand-secondary;
	color: white;

	a {
		color: white;
		text-decoration: underline;

		&:hover, &:focus {
			color: $brand-primary;
		}
	}
	p { margin-bottom: 2rem; }
	strong { color: $brand-primary; }


	&--headline {
		font-size: 1.125rem;
		text-transform: uppercase;
		letter-spacing: .75px;
	}

	&--form {
		padding-right: 3rem;

		.form-control {
			background-color: transparent;
			border: 0;
			border-radius: 0;
			border-bottom: 1px solid white;

			color: white;
		}

		.form-control:focus {
			box-shadow: unset;
			border-bottom: 2px solid white;
		}

		.form-floating > label {
			padding-left: 0;
			padding-right: 0;

			font-weight: 300;
			color: white;
		}

		.form-floating > .form-control, .form-floating > .form-control-plaintext {
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 0;
		}

		.form-floating > .form-control:focus ~ label, 
		.form-floating > .form-control:not(:placeholder-shown) ~ label, 
		.form-floating > .form-control-plaintext ~ label, 
		.form-floating > .form-select ~ label {
			transform: scale(0.85) translateY(-0.5rem) translateX(-0.05rem);
		}
	}
}

.aie-small-footer {
	padding: 2rem;
	background: $brand-primary;

	font-weight: 300;
	text-align: center;
}

.aie-map {
	width: calc(100% + ((100vw - 79.5rem)/2));
	height: 100%;

	@media screen and (max-width: $lg-max) {
		width: calc(100% + ((100vw - 68.25rem)/2));
	}

	@media screen and (max-width: $md-max) {
		width: calc(100% + ((100vw - 57rem)/2));
	}

	@media screen and (max-width: $sm-max) {
		position: relative;
		left: calc((42rem - 100vw)/2);
		width: 100vw;
		min-height: 25rem;
	}
	@media screen and (max-width: $xs-max) {
		position: relative;
		left: calc((30.75rem - 100vw)/2);
		width: 100vw;
		min-height: 25rem;
	}
	@media screen and (max-width: $xxs-max) {
		left: -1.5rem;
	}
}




// ===============================
//  CONTENT SECTION
// ===============================

.content-section {
	margin-top: 7.5rem;
	margin-bottom: 7.5rem;
	font-weight: 300;

	@media screen and (max-width: $sm-max) {
		margin-top: 5rem;
	}
}

.img-to-side {
	position: absolute;
	overflow: hidden;

	&--left { right: 0; }
	&--right { left: 0; }

	width: calc(100% + ((100vw - 81rem)/2));
	height: 100%;

	img {
		min-width: 100%;
	    min-height: 100%;
	    object-fit: cover;
	}

	@media screen and (max-width: $lg-max) {
		width: calc(100% + ((100vw - 69.75rem)/2));
	}

	@media screen and (max-width: $md-max) {
		width: calc(100% + ((100vw - 58.5rem)/2));
	}

	@media screen and (max-width: $sm-max) {
		position: static;
		width: 100%;
	}
}