html, body {
	--scroll-behavior: smooth;
  	scroll-behavior: smooth;
}

// Used by the fixed navbar
body {
    padding-top: $navbar-height;

    font-family: $font-family;
    line-height: $line-height-base;

    color: $text-color;
}

a {
    color: $brand-primary;
    text-decoration: none;
}

a:hover, a:focus {
    color: lighten($brand-primary, 10%);
    text-decoration: underline;
}


//
// DEFAULT LAYOUT STYLESHEET
//


h1, .h1 {
    font-size: 41px;
}
h2, .h2 {
    font-size: 34px;
}
h3, .h3 {
    font-size: 18px;
}
h4, .h4 {
    font-size: 20px;
}
h5, .h5 {
    font-size: 16px;
}
h6, .h6 {
    font-size: 14px;
}

h1, .h1, h2, .h2, h3, .h3 {
    margin-top: 27px;
    margin-bottom: 13.5px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    line-height: 1.1;
    font-weight: bold;
    color: $headings-color;
}




// ----------------
// NAVBAR
// ----------------

.navbar {
    height: $navbar-height;
    padding-top: .9rem;
    padding-bottom: .9rem;

    a:hover, a:focus, a.focus {
        text-decoration: none;
    }

    .dropdown-item.active, .dropdown-item:active {
        background-color: $brand-accent;
    }
}

//
// Auto Hide Navbar
//

.navbar-autohide {
    transition: transform .5s;
}

@media (min-width: $md-min) {
    .navbar-autohide.is-hidden {
        transform: translateY(-($navbar-height + 2px));
    }
}



// ----------
// FOOTER
// ----------





// ------------------------------
// ERROR PAGE
// ------------------------------

.error-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;
    padding-top: 7.5rem;
    padding-bottom: 10rem;

    &-content {
        text-align: center;

        h1 {
            font-size: 5rem;
        }
    }
}



// --------------------------------
// STICKY FOOTER
// --------------------------------

.sticky-footer-html {
    height: 100%;
}

.sticky-footer-body {
    display: flex;
    flex-direction: column;

    height: 100%;
    
    .sticky-footer-wrapper {
        flex: 1 0 auto;
    }

    .sticky-footer {
        flex-shrink: 0;
    }
}