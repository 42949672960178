@import "~bootstrap/scss/bootstrap";

@import 'photoswipe-dynamic-caption-plugin/photoswipe-dynamic-caption-plugin.css';

@import "theme/mixins";
@import "theme/variables";

// For first level entry path
$assets-url: "../../assets";

@import "theme/default";

@import "theme/_project";