//
// Variables file
//
// Space for any custom variables used by this application
//

// Assets
// --------------------------------------------------
// For second level entry paths (pages, layouts, etc.)
$assets-url: "../../../assets";

// Brands
// --------------------------------------------------
$brand-primary: #F3E500;
$brand-secondary: #006A5B;

$brand-success: #4eb76e;
$brand-warning: #cbb956;
$brand-danger:  #bf5329;
$brand-accent: #006A5B;

$gray-base: #000;
$gray-darker: lighten($gray-base, 13.5%); // #222
$gray-dark: lighten($gray-base, 20%);   // #333
$gray: lighten($gray-base, 33.5%); // #555
$gray-light: lighten($gray-base, 46.7%); // #777
$gray-lighter: lighten($gray-base, 93.5%); // #eee


// Typography
// --------------------------------------------------
$font-family: Nexa, sans-serif;
$line-height-base: 1.7;
$font-size-base: 16px;
$font-size-h3: 18px;
$text-color: #006A5B;
$headings-color: #006A5B;
$headings-font-weight: bold;
$jumbotron-heading-color: $headings-color;
$border-radius-base: 4px;


// Breakpoints
// --------------------------------------------------

// Extra small screen
$xs-min: 576px;

// Small screen / tablet
$sm-min: 768px;

// Medium screen / desktop
$md-min: 992px;

// Large screen / wide desktop
$lg-min: 1200px;

// Extra large screen
$xl-min: 1400px;

// So media queries don't overlap when required, provide a maximum
$xxs-max: ($xs-min - 1);
$xs-max: ($sm-min - 1);
$sm-max: ($md-min - 1);
$md-max: ($lg-min - 1);
$lg-max: ($xl-min - 1);


// Navbar
// --------------------------------------------------
$navbar-height: 70px;


// TODO nastavenie loading stripe farieb
// $navbar-inverse-stripe-color-active: #ffffff;
// $navbar-inverse-stripe-color-hover: #e67e22;
// $navbar-default-stripe-color-active: #64ae5b;
// $navbar-default-stripe-color-hover: #93dc8a;

