//
// Mixins file
//
// Space for any custom mixins used by this application
//



// =============================
//  AFTER / BEFORE CONTENT
// =============================


@mixin after-before() {
	display: block;
	content: '';
	position: absolute;
}



// =============================
//  MENU BURGER MIXINS
// =============================

@function rem($size) {
	$remSize: $size / 16px;
	@return $remSize * 1rem;
}
// The mixin
@mixin menu(
	$width: 1.5rem,
	$height: rem(2),
	$gutter: rem(3),
	$color: white,
	$round: 0,
	$transition-duration: 0.3s
	) {
	@if unit($width) == "px" {
		$width: rem($width);
	}
	@if unit($height) == "px" {
		$height: rem($height);
	}
	@if unit($gutter) == "px" {
		$gutter: rem($gutter);
	}

	// Set margins
	$margin: $height + $gutter;
	$margin-left: $width / 2;
	$margin-top: $height / 2;

	// Set specific styles for each bar
	$top: $height + $gutter;

	// set global Variables
	$menu-height: $height !global;
	$menu-gutter: $gutter !global;
	$menu-color: $color !global;
	$menu-margin: $margin !global;
	$menu-margin-left: $margin-left !global;
	$menu-margin-top: $margin-top !global;
	$menu-top: $top !global;

	// The code for the span
	user-select: none;
	position: absolute;
	margin: #{$margin-top} auto #{$margin} -#{$margin-left};
	text-indent: -999em;
	top: 50%;
	left: 50%;
	margin-top: -#{$menu-height/2};
	cursor: pointer;

	// Set styles to bars
	&,
	&:before,
	&:after {
		display: block;
		width: $width;
		height: $height;
		background-color: $color;
		transition: $transition-duration;
		opacity: 1;
		@if $round != 0 {
			border-radius: $round;
		}
	}
	// Bottom and top bar
	&:before,
	&:after {
		position: absolute;
		content: "";
	}

	// Top bar
	&:before {
		top: -#{$top};
	}

	// Bottom bar
	&:after {
		top: #{$top};
	}
}

@mixin menu-close {
	&:before,
	&:after {
		top: 0px;
		margin-top: -#{($menu-height/2) + ($menu-gutter + $menu-height)};
		@content;
	}
	& {
		background-color: transparent;
	}
	&:before {
		background-color: $brand-secondary;
		transform: translateY($menu-gutter + $menu-height) rotate(45deg);
	}
	&:after {
		background-color: $brand-secondary;
		transform: translateY($menu-gutter + $menu-height) rotate(-45deg);
	}
}

@mixin menu-reset {
	&:before,
	&:after {
		margin-top: 0;
		@content;
	}
	& {
		background-color: $menu-color;
	}
	&:before {
		transform: translateY(0) rotate(0deg);
		top: -#{$menu-top};
	}
	&:after {
		transform: translateY(0) rotate(0deg);
		top: #{$menu-top};
	}
}
@mixin menu-body {
	&,
	&:before,
	&:after {
		@content;
	}
}
@mixin menu-body-first {
	&:before {
		@content;
	}
}
@mixin menu-body-middle {
	& {
		@content;
	}
}
@mixin menu-body-last {
	&:after {
		@content;
	}
}

@mixin menu-body-close {
	&:before,
	&:after {
		@content;
	}
}